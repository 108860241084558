<template>
	<div>
		<CCard class="p-4">
			<form @submit.prevent="submit">
				<CCardHeader>
					<CRow>
						<CCol lg="6">
							<h5 v-if="!editMode">New DR / {{bookingParams.booking_no}}</h5>
							<h5 v-else>Update DR / {{bookingParams.booking_no}}/  {{dataParams.dr_id}}</h5>
						</CCol>
				     	<CCol lg="6">
							<div style="text-align: right">
				      			<CButton size="sm" @click="backToTable()" color="danger"><i class="fa fa-close"></i> Cancel</CButton> &nbsp;
					      		<CButton size="sm" class="px-3" color="info" type="submit"><i class="fa fa-save"></i> Save</CButton>
				      		</div>
					
						</CCol>
					</CRow>
				</CCardHeader>
				<CCardBody>
		<fieldset class="fieldset full-line">
			<legend> DR Information</legend>
				
						<CRow>
							
							<CCol lg="6">
								<label class="mb-1">Customer</label>
								<CInput
				                 
				                  v-model="bookingParams.customer.customer_name" 
				                  disabled
				                />
							</CCol>
							<CCol lg="6">
								<div class="form-group"> 
									<label class="mb-1">Delivery Date<span class="text-danger"> *</span></label>
									<Datepicker 
										input-class="form-control bg-white" 
										v-model="dataParams.delivery_date"
										
										:required="true"
									>
									</Datepicker>  
								</div>
							</CCol>
							
						</CRow>
						<CRow> 
							<CCol lg="12">
								<label class="mb-1">Address</label>
								<CInput
				                  
				                  v-model="dataParams.address"  
				                  :required="true"
				                />
							</CCol>
							
						</CRow>
						<CRow> 
							<CCol lg="6">
								<label class="mb-1">Contact Person</label>
								<CInput
				                
				                  v-model="dataParams.contact_person"   
				                />
							</CCol>
							<CCol lg="6">
								<label class="mb-1">Department</label>
								<CInput
				                 
				                  v-model="dataParams.department"   
				                />
							</CCol> 
						</CRow> 
		</fieldset>
		<fieldset class="fieldset full-line">
			<legend> DR Items</legend>
						<CRow v-for="(item, index) in dataParams.delivery_receipt_item"> 
							<CCol lg="2">
								<div class="form-group"> 
									<label class="mr-2 mb-1">Asset Type <span class="text-danger">*</span> </label>
									<v-select 
										label="setting_name" 
										:options="[
											{
												id: 'furnishing',
												setting_name: 'Furnishing'
											},
											{
												id: 'equipment',
												setting_name: 'Equipment'
											},
											{
												id: 'vehicle',
												setting_name: 'Vehicle'
											},
											
										]"
										:reduce="item => item.id"
										v-model="dataParams.delivery_receipt_item[index].asset_type"
										placeholder="Select..." 
									>
										<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.delivery_receipt_item[index].asset_type"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
									</v-select>
								</div>
							</CCol> 
							<CCol lg="2">
								<label class="mb-1">Asset No <span class="text-danger">*</span></label>
								<div class="input-group mb-2">
									<input type="text" class="form-control px-2" :disabled="!dataParams.delivery_receipt_item[index].asset_type" @click="assetSearchModalTrigger(true, index)" v-model="dataParams.delivery_receipt_item[index].no" readonly>
									<!-- <div class="input-group-append">
										<a href="javascript:void(0)" @click="assetSearchModalTrigger(true, index)" class="btn btn-primary" :class="{disabled : !dataParams.delivery_receipt_item[index].asset_type}">
											<i class="fa fa-search"></i> Search
										</a>
									</div> -->
									
								</div>
							</CCol>
							<!-- <CCol lg="2">
								<label>Asset No <span class="text-danger">*</span></label>
								<input v-if="dataParams.delivery_receipt_item[index].asset_type == ''" type="text" disabled class="form-control" value="Select Asset Type">
								<v-select 
									label="asset_no" 
									:options="furnishingList.data"
									:reduce="item => item.asset_no"
									v-model="dataParams.delivery_receipt_item[index].no"
									placeholder="Asset No" 
									v-if="dataParams.delivery_receipt_item[index].asset_type == 'furnishing'"
									@input="changeAsset(index)"
									
								>
									<template #search="{attributes, events}" v-if="dataParams.delivery_receipt_item[index].asset_type == 'furnishing'">
										<input
											class="vs__search"
											:required="!dataParams.delivery_receipt_item[index].no"
											v-bind="attributes"
											v-on="events"
										/>
									</template>
								</v-select>
								<v-select 
									label="asset_no" 
									:options="equipmentList.data"
									:reduce="item => item.asset_no"
									v-model="dataParams.delivery_receipt_item[index].no"
									placeholder="Asset No" 
									@input="changeAsset(index)"
									v-if="dataParams.delivery_receipt_item[index].asset_type == 'equipment'" 
								>
									<template #search="{attributes, events}" v-if="dataParams.delivery_receipt_item[index].asset_type == 'equipment'">
										<input
											class="vs__search"
											:required="!dataParams.delivery_receipt_item[index].no"
											v-bind="attributes"
											v-on="events"
										/>
									</template>
								</v-select> 
							</CCol> -->
							<CCol lg="1">
								<label class="mb-1">Qty <span class="text-danger">*</span></label>
								<CInput
				            
				                  v-model="dataParams.delivery_receipt_item[index].qty"   
				                  :required="true"
				                />
							</CCol>
							<CCol lg="2">
								<div class="form-group"> 
									<label class="mb-1">Unit <span class="text-danger">*</span></label>
									<v-select 
										label="id" 
										:options="[
											{id: 'unts'},
											{id: 'pcs'},
											{id: 'ltrs'},
											{id: 'mtrs'},
											{id: 'lot'},
											{id: 'box'},
											{id: 'set'},
											{id: 'drum'}
										]"
										:reduce="item => item.id"
										v-model="dataParams.delivery_receipt_item[index].unit"
										
									>
										<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.delivery_receipt_item[index].unit"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
									</v-select>
								</div>
							</CCol> 
							<CCol lg="5">
								<label class="mb-1">Particular <span class="text-danger">*</span></label>
								<CInput
				                
				                  :value="dataParams.delivery_receipt_item[index].particular_holder"  
								  @change="setParticular($event, index)"
				                  :required="true" 
				                />
							</CCol>
							<CCol lg="4">
								<label class="mb-1">Brand</label>
								<CInput
				                	v-model="dataParams.delivery_receipt_item[index].brand"  
				                  
				                  disabled
				                />
							</CCol>
								<CCol lg="3">
								<label class="mb-1">Serial No</label>
								<CInput
				                  
				                v-model="dataParams.delivery_receipt_item[index].serial_no"  
				                  disabled
				                />
							</CCol>
							<CCol lg="1" v-if="index != 0"> 
								<br/>
								<CButton size="sm" color="danger mt-2" @click="deleteItem(index)"><i class="fa fa-trash"></i></CButton> 
							</CCol>
						</CRow>
						<CRow >
							<CCol lg="2">
								<CButton size="sm" color="success" @click="addItem"><i class="fa fa-plus"></i> New Item</CButton>
							</CCol>
						</CRow>
						<br/>
						
						<CRow> 
							<CCol lg="12">
								<label class="mb-1">Remarks</label>
								<CInput
				                 
				                  v-model="dataParams.remarks"   
				                />
							</CCol>
							
						</CRow>
		</fieldset>
		<fieldset class="fieldset full-line">
			<legend> References </legend>
						<CRow> 
							<CCol lg="6">
								<label class="mb-1">Released by</label>
								<CInput
				                  
				                  v-model="dataParams.released_by"   
				                />
							</CCol>
							<CCol lg="6">
								<label class="mb-1">Checked By</label>
								<CInput
				                
				                  v-model="dataParams.checked_by"   
				                />
							</CCol> 
						</CRow> 
						<CRow> 
							<CCol lg="6">
								<label class="mb-1">Received By</label>
								<CInput
				                 
				                  v-model="dataParams.received_by"   
				                />
							</CCol>
							<CCol lg="6">
								<div class="form-group"> 
									<label class="mb-1">Delivered By <span class="text-danger">*</span></label>
									<v-select 
										label="name" 
										:options="driverList.data"
										:reduce="item => item.id"
										v-model="dataParams.delivered_by"
										placeholder="Select..." 
									>
										<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.delivered_by"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
									</v-select>
								</div>
							</CCol> 
						</CRow> 
						
						
		</fieldset>			
				</CCardBody>
			</form>
		</CCard>


		<CModal :title="'Asset No. List'" :show.sync="showAssetModal" color="info" size="lg"> 
			<div class="pl-3 pr-3 pb-3"> 
				<CRow class="mb-1">
					<CCol lg="12">
						<div class="input-group mb-1">
							<input type="search" :value="previous_val" class="form-control" ref="asset_no_search" placeholder="Enter asset # to search.">
							<div class="input-group-append">
								<a href="javascript:void(0)" class="btn btn-primary" @click="searchAssetNo">
									<i class="fa fa-search"></i> Search
								</a>
							</div>
							
						</div>
					</CCol> 
				</CRow> 
				<CRow class="mb-2">
					<CCol lg="12">
						<small v-if="is_searching">
								<i class="fa fa-refresh fa-spin"></i> Searching ...
							</small>
						<div v-if="asset_no_list_holder.length">
							<div class="row mb-0" v-if="!is_searching">
								<div class="col-md-6 mb-0 pt-1">
									<small v-if="asset_no_list_holder.length == asset_no_list.length">
										<span class="text-success font-w600">{{asset_no_list_holder.length}}</span> record/s found.
									</small>
									<small v-else>
										<span class="text-success font-w600">{{asset_no_list.length}} <em class="text-muted">of</em> {{asset_no_list_holder.length}}</span> record/s found.
									</small>
								</div>
								<div class="col-md-6 mb-0">
									<input type="search" @keyup="filterList($event)" @search="filterList($event)" class="form-control form-control-sm float-right col-md-6" placeholder="Search list">
								</div>
							</div>
							
							<div class="list-group mt-1" style="max-height:500px;overflow-y:auto" >
								<a href="javascript:void(0)" class="list-group-item list-group-item-action flex-column align-items-start" v-for="(asset, index) in asset_no_list" :key="index">
									<div class="d-flex w-100 justify-content-between">
									<h4 class="mb-0">{{asset.asset_no}}</h4>
									<template v-if="isSelected(asset)">
										<button class="px-2 btn btn-sm btn-success" @click="selectAssetNo(asset)"><i class="fa fa-check "></i> Select</button>
									</template>
									<template v-else>
										<button class="px-2 btn btn-sm btn-secondary" disabled><i class="fa fa-check "></i> Selected</button>
									</template>
									</div>
									<small class="text-muted"><em>{{asset.description}}</em></small>
								</a>
							</div>
						</div>
						<div class="list-group" v-else>
							<label v-if="!is_searching" class="mb-0"><em>Nothing to display.</em></label>
						</div>
					</CCol> 
				</CRow> 
			</div>
			<div slot="footer" class="w-100">
				<CButton style="border-radius: .2rem; color: white;" color="danger" class="ml-1 mr-1 float-right" @click="assetSearchModalTrigger(false)">
					Close
				</CButton>  
			</div>
			<div slot="footer" class="w-100"></div>
		</CModal>
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from 'axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css';
import '../../style.css';
import Datetime from 'vuejs-datetimepicker'; 
import moment from 'moment';
import 'regenerator-runtime/runtime';

export default {
	async mounted(){  
		
		// this.getCustomer();
		// this.getBookingType();
		// this.getDepot();
		// this.getCommodityType();
		// this.getOrigin();
		// this.getDestination();
		// this.getVehicle();
		// this.getHelperList();
		
		// this.getVendor();
		// this.getVehicleType();
		// this.getMakerModel();
		
		
		if(this.$route.params.dr_id != undefined){
			this.editMode = true;
		} 
		if(this.$route.params.dr_id){
			
		this.getData();
		} 

		// this.getEquipment();
		// this.getFurnishing();
		this.getDriverList();
		this.getBooking();
		

	},
	data(){
		return{
			bookingParams: {
				booking_no: "",
				customer: {}
			},
			formModal: false,
			showViewModal: false,
			dataList: [],
			dataListInactive: [],
			depotName: "", 
			dataParams:{
				booking_id: "",
				customer_id: "",
				delivery_date: "",
				address: "",
				contact_person: "",
				department: "",
				released_by: "",
				remarks: "",
				checked_by: "",
				received_by: "",
				delivered_by: "",
				dr_id: "",
				delivery_receipt_item: [
					{
						asset_type: "",
						no: "",
						qty: "",
						unit: "units",
						particular: "",
						brand: "",
						serial_no: ""
					}
				]
			},
			
			editMode: false,
			selectedRow: {},
			uploadPercentage: 0,
			config,


		
			driverList: {
				data: []
			},
			equipmentList: {
				data: []
			},
			furnishingList: {
				data: []
			},
			equipmentDetails: {},
			furnishingDetails: {},
			do_not_reload : false,
			showAssetModal : false,
			dr_index: null,
			asset_type_selected: null,
			asset_no_list: [],
			asset_no_list_holder: [],
			previous_val : "",
			is_searching : false,

		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect, Datetime},
	methods: {  
		filterList(ev) {
			let qry = ev.target.value;
			this.asset_no_list = [...this.asset_no_list_holder]

			if(qry?.length) {
				qry = qry.toLowerCase();
				this.asset_no_list = this.asset_no_list.filter(item => item.asset_no.toLowerCase().includes(qry))
			}
		},
		isSelected(asset) {
			return !(this.dataParams.delivery_receipt_item[this.dr_index]?.no == asset.asset_no)
		},
		selectAssetNo(asset) {
			if(this.asset_type_selected == 'vehicle') {
				this.dataParams.delivery_receipt_item[this.dr_index].no = asset.asset_no;
				this.showAssetModal = false;
				this.dataParams.delivery_receipt_item[this.dr_index].brand = asset.maker_model;
				this.dataParams.delivery_receipt_item[this.dr_index].serial_no = asset.engine_no;
				this.dataParams.delivery_receipt_item[this.dr_index].particular_holder =`${asset.vehicle_type} (${asset.plate_no})`;
			}
			else {
			this.dataParams.delivery_receipt_item[this.dr_index].no = asset.asset_no;
			this.showAssetModal = false;
			this.dataParams.delivery_receipt_item[this.dr_index].brand = asset.mfg_brand;
			this.dataParams.delivery_receipt_item[this.dr_index].serial_no = asset.serial_no;
			this.dataParams.delivery_receipt_item[this.dr_index].particular_holder = asset.description;
			console.log(this.dataParams.delivery_receipt_item, asset)
		}
			this.asset_no_list = [];
			this.asset_no_list_holder = [];
		},

		searchAssetNo() {
			let query = this.$refs.asset_no_search.value;
			if(query?.trim()?.length == 0) {
				this.asset_no_list = [];
				this.asset_no_list_holder = [];
				return;
			}

			this.previous_val = query;
			this.is_searching = true;

			let url = `${config.api_path}/reference/equipment-list`;
			if(this.asset_type_selected == 'furnishing')
				url = `${config.api_path}/reference/furnishing-list`;
			if(this.asset_type_selected == 'vehicle')
				url = `${config.api_path}/reference/vehicle-list`;	
			axios.get(url, {
				params: {query}
			}).then(res => {
				const {data, status} = res.data;
				this.asset_no_list = data;
				this.asset_no_list_holder = data;
				this.is_searching = false;
			}).catch(err => {
				this.is_searching = false;
			})
		},
		assetSearchModalTrigger(show, index) {
			this.showAssetModal = show;
			this.asset_type_selected = show ? this.dataParams.delivery_receipt_item[index].asset_type : null;
			this.dr_index = index;
			this.previous_val = this.dataParams.delivery_receipt_item[index].no ?? '';
			this.$nextTick(() => {
				this.$refs.asset_no_search.focus()
			})
			
		},
		backToTable(){
	    	this.$router.push('/booking/'+this.$route.params.id+'/dr')
	    },
	 	setParticular(ev, index) {
			this.dataParams.delivery_receipt_item[index].particular = ev;
		},
	    submit(){
	    	var ax = {}; 
			this.do_not_reload = true;
	    	console.log(this.dataParams.delivery_date)
	    	this.dataParams.delivery_date = this.dataParams.delivery_date = moment(this.dataParams.delivery_date).startOf('day').unix();
	    	console.log(this.dataParams.delivery_date)
			
			if(this.dataParams.delivery_receipt_item.length) {
				for(const [index, item] of this.dataParams.delivery_receipt_item.entries()) {
					if(!item.particular) this.dataParams.delivery_receipt_item[index].particular = this.dataParams.delivery_receipt_item[index].particular_holder
				}
			}
			
	    	if(this.editMode){
	    		ax = axios.put(config.api_path+"/deliveryReceipt/"+this.$route.params.dr_id, this.dataParams)
	    	}
	    	else{
	    		ax = axios.post(config.api_path+"/deliveryReceipt", this.dataParams)
	    	}
			this.$showLoading(true);
	    	ax.then(response => {
				this.$showLoading(false);
	    		var text = 'Delivery receipt successfully added!';
	    		if(this.editMode){
	    			text = 'Delivery receipt successfully updated!';
	    		}
	    		Swal.fire({
					title: 'Success!',
					text,
					icon: 'success', 
				})
	    		this.formModal = false
	    		this.editMode = false;
	    		this.dataParams = {
					delivery_date: "",
					address: "",
					contact_person: "",
					department: "",
					released_by: "",
					remarks: "",
					checked_by: "",
					received_by: "",
					delivered_by: "",
					dr_id: "",
					delivery_receipt_item: [
						{
							asset_type: "",
							no: "",
							qty: "",
							unit: "units",
							particular: "",
							brand: "",
							serial_no: ""
						}
					]
				}
	    		this.$router.push('/booking/'+this.$route.params.id+'/view_dr/'+response.data.data.id)
	    	})
		
			.catch(err => {
				this.$showLoading(true);
			})
	    },


	    // getEquipment(){

	    // 	axios.get(config.api_path+'/equipment?page=1&limit=10000000')
	    // 	.then(response => {
	    // 		this.equipmentList = response.data;
	    // 		this.equipmentList.data = this.equipmentList.data.map((value, index)=>{
	    // 			value.value = value.id;
		// 			value.label = value.asset_no;
	    // 			// value.label = value.equipment_prep_no;
	    // 			return value;
	    // 		})
	    // 	})

	    // },

	    // getFurnishing(){

	    // 	axios.get(config.api_path+'/furnishing?page=1&limit=10000000')
	    // 	.then(response => {
	    // 		this.furnishingList = response.data;
	    // 		this.furnishingList.data = this.furnishingList.data.map((value, index)=>{
	    // 			value.value = value.id;
	    // 			value.label = value.asset_no;
	    // 			return value;
	    // 		})
	    // 	})

	    // },


	    

	    updateCompanyOwned(e){
	    	this.dataParams.company_owned = e;
	    },




	    getDriverList(){

	    	axios.get(config.api_path+'/personnel?status=active&page=1&limit=1000000')
	    	.then(response => {
	    		this.driverList = response.data; 
	    		this.driverList.data = this.driverList.data.filter((value, index)=>{
	    			return value.employment_type == "direct_hire";
					return value.personnel_type == "driver";
	    		})
	    	})

	    },
	    getData(){
			this.$showLoading(true)
			axios.get(config.api_path+"/deliveryReceipt/"+this.$route.params.dr_id)
			.then(response=>{  
				this.$showLoading(false)
				this.dataParams = response.data.data; 
				this.dataParams.delivery_receipt_item = this.dataParams.delivery_receipt_item.map(item => {
					item.particular_holder = item.particular;
					return item;
				})
				this.dataParams.delivery_date = moment.unix(this.dataParams.delivery_date).format('MMMM DD, YYYY')
			})

		}, 

		getBooking(){
			this.$showLoading(true)
			axios.get(config.api_path+"/booking/"+this.$route.params.id)
			.then(response=>{  
				this.bookingParams = response.data.data;  
				this.dataParams.delivery_date = moment.unix(this.bookingParams.delivery_date).format('MMMM DD, YYYY')
				this.dataParams.address = this.bookingParams.customer.address_a;
				this.dataParams.booking_id = this.$route.params.id;
				this.dataParams.customer_id = this.bookingParams.customer_id;
				this.dataParams.delivered_by = this.bookingParams.driver_id;
				this.$showLoading(false)
			})
		},

		addItem(){
			this.dataParams.delivery_receipt_item.push({
				asset_type: "",
				no: "",
				qty: "",
				unit: "units",
				particular: "",
				brand: "",
				serial_no: ""
			})
		},

		deleteItem(index){
			this.dataParams.delivery_receipt_item.splice(index, 1);
		},

		changeAsset(i){
			
			var delivery_receipt_item = this.dataParams.delivery_receipt_item;
			console.log(delivery_receipt_item)
			if(delivery_receipt_item[i].asset_type == 'furnishing'){
				var asset = this.furnishingList.data.filter((value, index)=>{
					return value.asset_no == delivery_receipt_item[i].no;
				})
				if(asset.length > 0){
					// if(delivery_receipt_item[i].particular == ""){
						// delivery_receipt_item[i].particular = asset[0].description;
					delivery_receipt_item[i].particular = asset[0].description;//delivery_receipt_item[i].particular
					// } 
					// delivery_receipt_item[i].particular_holder = asset[0].description;
					delivery_receipt_item[i].brand = asset[0].mfg_brand;
					delivery_receipt_item[i].serial_no = asset[0].serial_no;
				}
			}
			if(delivery_receipt_item[i].asset_type == 'equipment'){
				var asset = this.equipmentList.data.filter((value, index)=>{
					return value.asset_no == delivery_receipt_item[i].no;
				})
				if(asset.length > 0){
					// if(delivery_receipt_item[i].particular == ""){
						// delivery_receipt_item[i].particular = asset[0].description;
					delivery_receipt_item[i].particular = asset[0].description; //delivery_receipt_item[i].particular
					// } 
					// delivery_receipt_item[i].particular_holder = asset[0].description;
					delivery_receipt_item[i].brand = asset[0].mfg_brand;
					delivery_receipt_item[i].serial_no = asset[0].serial_no;
				}
			}
		}

 	}, 
	
	watch: {
		dr_index() {
			this.asset_no_list = [];
			this.$refs.asset_no_search.value ="";
			this.asset_no_list_holder = [];
		},
		// showAssetModal(val) {
		// 	if(!val) {
		// 		// this.asset_no_list = [];
		// 		this.$refs.asset_no_search.value ="";
		// 		this.dr_index = null;
		// 	}
		// }
		// dataParams: {
		// 	// This will let Vue know to look inside the array
		// 	deep: true,

		// 	// We have to move our method to a handler field
		// 	handler(){
		// 		var delivery_receipt_item = this.dataParams.delivery_receipt_item;
				
		// 		for(var i = 0; i < delivery_receipt_item.length; i++){
		// 			if(delivery_receipt_item[i].asset_type == 'furnishing'){
		// 				var asset = this.furnishingList.data.filter((value, index)=>{
		// 					return value.asset_no == delivery_receipt_item[i].no;
		// 				})
		// 				if(asset.length > 0){
		// 					// if(delivery_receipt_item[i].particular == ""){
		// 						// delivery_receipt_item[i].particular = asset[0].description;
		// 					delivery_receipt_item[i].particular_holder = asset[0].description;//delivery_receipt_item[i].particular
		// 					// } 
		// 					// delivery_receipt_item[i].particular_holder = asset[0].description;
		// 					delivery_receipt_item[i].brand = asset[0].mfg_brand;
		// 					delivery_receipt_item[i].serial_no = asset[0].serial_no;
		// 				}
		// 			}
		// 			if(delivery_receipt_item[i].asset_type == 'equipment'){
		// 				var asset = this.equipmentList.data.filter((value, index)=>{
		// 					return value.asset_no == delivery_receipt_item[i].no;
		// 				})
		// 				if(asset.length > 0){
		// 					// if(delivery_receipt_item[i].particular == ""){
		// 						// delivery_receipt_item[i].particular = asset[0].description;
		// 					delivery_receipt_item[i].particular_holder = asset[0].description; //delivery_receipt_item[i].particular
		// 					// } 
		// 					// delivery_receipt_item[i].particular_holder = asset[0].description;
		// 					delivery_receipt_item[i].brand = asset[0].mfg_brand;
		// 					delivery_receipt_item[i].serial_no = asset[0].serial_no;
		// 				}
		// 			}
		// 		}
		// 	}
		// }
    } 
}
</script>
